import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Image from "../../components/image"
import SEO from "../../components/seo"
import styles from "./../work.module.sass"
import { AnchorLink } from "gatsby-plugin-anchor-links";


const HangedMan = () => (
  <Layout>
    {/*---- Work Detail ----*/}
    <SEO title="The Hanged Man" />

    <article className={styles.workDetail}>
      <div style={{ padding:`56.25% 0 0 0`, position:`relative`}}>
        <iframe src="https://player.vimeo.com/video/377393102?title=0&byline=0&portrait=0" 
          style={{ position:`absolute`, top:`0`, left:`0`, width:`100%`, height:`100%`}} frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>

      <header className={styles.workDetail_header}>
        <h1 className={styles.workDetail_title}>The Hanged Man</h1>
        <h2 className={styles.workDetail_subTitle}>A robotic user interface to animate clothes at the boundary between daily situation and storytelling world.</h2>
        <ul className={styles.workDetail_additionalInfo}>
          <li>2019 / Academic Project (Solo)</li>
          <li>Role: Interaction Designer, Creative Technologist, Design Researcher</li>
          <li>
            Supervisors: Jessica In,  Parker Heyl, Ross Cairns and Ruairi Glynn 
            (<a target="_blank" href="https://www.ucl.ac.uk/bartlett/architecture/programmes/postgraduate/march-design-for-performance-and-interaction">
              MArch DfPI, UCL</a>)
          </li>
          <li>Main Tools: Arduino, Processing, Kinect, Fusion 360</li>
          <li>Keywords: Robotic User Interface, Physical Computing, Animacy Perception</li>
        </ul>
      </header>

      <section className={styles.workDetail_section}>
        <h3 className={styles.workDetail_section_title}>Overview</h3>
        <p>
          We have many opportunities to experience narratives in our everyday life. 
          Our very human ability to connect cause and effect allows us to consider the stories behind events and experiences. As storytelling elements, animating daily objects can create compelling moments into daily scenarios. 
          The Hanged Man brings to life clothing displayed in apparel shop windows – an interstitial space considered as a layer a retail brand’s story and the daily life of a shopper.
        </p>
        <p>
          As a robotic user interface to animate retail clothing through interaction with people, The Hanged Man aims to attract people by moving and mimicking the observers. Employing the form of a clothes hanger into a computational interface to animate clothing, each brand can use the interface to animate and tell its own story.
        </p>
      </section>

      <section className={styles.workDetail_section}>
        <h3 className={styles.workDetail_section_title}>Design Process</h3>

        <h4 className={styles.workDetail_section_subTitle}>Context and Existing Research</h4>
        <p>
          It is often seen that people animate physical materials to describe stories in daily communication. 
          The main interest of this project is how to design a new communication tool by applying animated common products for everyday life. 
        </p>
        <p>
          Robots, especially humanoids, sometimes have "the Uncanny Valley" issue that people feel strangeness on their design. 
          Meanwhile, there are some research and artworks to employ inhuman-like forms, such as animals, daily objects and abstract motion, 
          into designing the alternative robotic user interface [<AnchorLink to={`/works/hangedMan#bibliography_1`}>1</AnchorLink>, <AnchorLink to={`/works/hangedMan#bibliography_2`}>2</AnchorLink>]. 
        </p>
        <p>
          Inhuman-like characters and motion have also been depicted in animation over decades. 
          In order to convey stories deeply, the animation industry has developed principles how to draw expressive motion with both human-like and inhuman-like characters [<AnchorLink to={`/works/hangedMan#bibliography_3`}>3</AnchorLink>]. 
          Therefore, this project attempts to apply the methods of animation and storytelling into designing a new robotic user interface.
        </p>
        <p>
          According to Storr, people's brains radically simplify the world with narrative in order to comprehend chaotic information in everyday life [<AnchorLink to={`/works/hangedMan#bibliography_4`}>4</AnchorLink>] 
          and this means daily situation is surrounded by many elements of storytelling. 
          From this perspective, I considered robotic user interface with animated daily items could make new communicational experience driven by the elements of narrative. 
        </p>
        <p>
          In order for the implementation, my project picks up the specific place of store window, specifically apparel store’s display, 
          because it can be considered as one of the intermediate between daily place and brand's storytelling world. 
          Additionally, a prior research suggests that clothing retailers focusing on displaying whole of the product fit and store image successfully draw customers more than others focusing on just promotion 
          [<AnchorLink to={`/works/hangedMan#bibliography_5`}>5</AnchorLink>]. 
          This fact supports my project in the practical aspect of advertisement.
        </p>
        <div className={styles.workDetail_largeImage_wrapper}>
          <Image fileName={"hangedMan/process_pic01.png"} style={{ margin: `100 100`}}  />
        </div>


        <h4 className={styles.workDetail_section_subTitle}>Analysis and Ideation</h4>
        <p>
          The principles of animation tend to focus on the relationship between motion and materials' softness, such as squashing and stretching characters' form and follow through and overlapping motion with the moment of inertia [<AnchorLink to={`/works/hangedMan#bibliography_6`}>6</AnchorLink>, <AnchorLink to={`/works/hangedMan#bibliography_7`}>7</AnchorLink>].  
          For this reason, this project decides to employ clothes as a part of the user interface and animates this daily soft material as a computational user interface. 
        </p>


        {/*---- Aladdin Example ----*/}
        <div className={styles.workDetail_images_wrapper} style={{ position:`relative`, paddingBottom: `1.45rem` }}>
          <div className={styles.workDetail_images_image} style={{ background:`#000`, padding:`30.25% 0 0 0`, position:`relative`}}>
            <iframe src="https://player.vimeo.com/video/93206523?byline=0&portrait=0" style={{ position:`absolute`, top:`0`, left:`0`, width:`100%`, height:`100%`}} frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
          </div>
          <div className={styles.workDetail_images_image} style={{ background:`#000`, padding:`30.25% 0 0 0`, position:`relative`}}>
            <iframe src="https://www.youtube.com/embed/87FTQUPyqb8?controls=1&amp;start=8" style={{ position:`absolute`, top:`0`, left:`0`, width:`100%`, height:`100%`}} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>


        <p>
          At the same time, in oder to design the system, I also examined the existing skeletons for clothes by defining two axises: abstract vs. human-like form and interactive vs. static. 
          I considered that the abstract form, like clothes hanger, is suitable for the medium working with narrative because it should not disturb the storytelling.
        </p>
        <div className={styles.workDetail_largeImage_wrapper}>
          <Image fileName={"hangedMan/process_pic02.png"} />
        </div>



        <h4 className={styles.workDetail_section_subTitle}>Design</h4>
        <p>
           After the analysis, I started to design a prototype named Naked. 
          It is a robotic user interface formed like a clothes hanger. 
        </p>
        <p>
          The first prototype had simple actions and it has no interaction with people. 
          Although it does not interact technically, the observer associates this machine with kinds of character and narrative. 
        </p>
        <p>
          The second prototype has a function to mimic the observer's behaviour. 
        </p>
        <p>
          I designed the third prototype called 'The Hanged Man' afterwards. It is designed by applying a structure of the six axis robotic arm in order to move precisely more than previous prototypes. 
          It is also coded to mimic the user’s motion but also the action has faint arrangement in order for exaggeration.
        </p>


        <h4 className={styles.workDetail_section_subTitle}>Demo</h4>
        <p>
          I had several opportunities to demonstrate and test the prototypes in art events and public places, including Samsung's concept store and one of the largest retail markets in London. 
          I got various reaction and feedback from the visitors. The following is the representative comments.
        </p>
        <ul className={styles.workDetail_list}>
          <li>Exhibited the second prototype at UCL, Here East, Jun 2019.</li>
          <li>Exhibited the second prototype at Samsung KX, Aug 2019. </li>
          <li>Demonstrated the third prototype at Camden Market, Dec 2019.</li>
          <li>Exhibited the third prototype at Bartlett main campus, Dec 2019.</li>
        </ul>
        {/*
        <p>
          I got various reaction and feedback from the visitors. The following is the representative comments.
        </p>
        <ul className={styles.workDetail_list}>
          <li>"The project should consider about the total set of installation because it can affect on the story this device tells us."</li>
          <li>"Even though it cannot move perfectly, it seems quite cute. The cuteness might be one of the most important factor in this project."</li>
          <li>"The device could be used for other aims such as tele-presence application."</li>
          <li>"The device should be mass produced and interact each other."</li>
        </ul>
        */}

        {/*---- Samsung Filming ----*/}
        <div style={{ padding:`56.25% 0 0 0`, position:`relative`}}>
          <iframe src="https://player.vimeo.com/video/358906397?byline=0&portrait=0" 
            style={{ position:`absolute`, top:`0`, left:`0`, width:`100%`, height:`100%`}} frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        </div>



        <h4 className={styles.workDetail_section_subTitle}>Conclusion and Next Step</h4>
        <p>
          The prototypes and user feedback show that the interactive system animating daily objects contains a lot of potential for different usages, such as daily communication, commerce and art, which is driven by elements of storytelling. 
          Creators can apply this fact in order to design communication tools which focuses on interactivity and narratives.
        </p>
        <p>
          For the next step, 
          I would like to improve the system to have collective user interfaces interacting each other in order to create more immersive experience.
        </p>
        <p>
          Another consideration is to develop a system in which the interface's motion is automatically arranged based on the context. 
        </p>

        <h4 className={styles.workDetail_section_subTitle}>External Evaluation</h4>
        <ul className={styles.workDetail_list}>
          <li>Award (Jury Selection) - <a href="https://j-mediaarts.jp/award/single/hanger-like-interface-to-animate-clothes-the-hanged-man/" target="_blank">24th JAPAN MEDIA ARTS FESTIVAL</a></li>
          <li>
            Nomination - <a href="https://www.inno.go.jp/result/2020/generation/nominate/" target="_blank">Generation Award category of INNO-vation progmram</a> organised by the Ministry of Internal Affairs and Communications.
          </li>
          <li>
            Adopted Project (a development plan from this project) - <a href="https://www.inno.go.jp/result/2020/hakai/challenger/" target="_blank">Disruptive Challenge Selection of INNO-vation progmram</a> as well.
          </li>
          <li>
            Semi-final Project (a development plan from this project) - <a href="https://tokyo-startup.jp/english" target="_blank">Tokyo Startup Gateway Contest 2020</a> organisied by Tokyo Metropolitan Government.
          </li>
        </ul>

        <h4 className={styles.workDetail_section_subTitle} >Bibliography: </h4>
        <ol className={styles.workDetail_numberingList}>
          <li id="bibliography_1">
            Sugiura, Y. et al. (2012) ‘PINOKY: A ring that animates your plush toys’, in Conference on Human Factors in Computing Systems - Proceedings. doi: 10.1145/2207676.2207780.
          </li>
          <li id="bibliography_2">
            Watanabe, K. et al. (2013) ‘SyncPresenter : Turntable Presentation System that Synchronizes Motion and Sound’, Information Processing Society of Japan Interaction 2013, pp. 488–489.
          </li>
          <li id="bibliography_3">
            Thomas, F. and Ollie, J. (1997) 'The Illusion of Life: Disney Animation'.
          </li>
          <li id="bibliography_4">
            Storr, W. (2019) ‘The Science of Storytelling’.
          </li>
          <li id="bibliography_5">
            Sena, S., Block, L. G., Chandran, S. (2002) 'Window displays and consumer shopping decisions', in Journal of Retailing and Consumer Services, 9, pp.277–290
          </li>
          <li id="bibliography_6">
            Lodigiani, V. (2014) 'The Illusion of Life'. Available at: <a href="https://centolodigiani.com/the-illusion-of-life" target="_blank">https://centolodigiani.com/the-illusion-of-life</a>
          </li>
          <li id="bibliography_7">
            'Aladdin' (2019) [Film]. Directed by Guy Ritchie.
          </li>
        </ol>

      </section>
    </article>
  </Layout>
)

export default HangedMan
